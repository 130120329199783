import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import useSmileAssessmentState from "../helpers/smileAssessmentState"
import useModalState from '../helpers/modalState';


import * as styleSheet from "./dentist.module.scss"

const DentistTemplate = (props) => {

	const {
		data: {
			dentist,
			dentist: {
				id: dentistId,
				title,
				ACFDentist: {
					headshot,
					introBGTablet,
					introBGDesktop,
					introBGXL,

					lastName,
					dentistInfo: {
						left: {
							specialitiesHeading,
							specialities,
							insurancesHeading,
							insurances
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								makeAnAppointmentLinkText
							},
              smileAssessment,
							contentHeading,
							dentistContent: content,
							callout: {
								calloutBlurb,
								calloutHeading,
								calloutLink ,
								displayCallout
							}
						}
					}
				},
			},
			locations: {
				nodes: dentistLocations
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							mobileNavigationLogo
						}
					}
				}

			},
			rightArrowYellow,
			teamPage,
			arrowBlue
		}
	} = props;

  const [showAppointmentModal, setShowAppointmentModal] = useModalState();
  function showModal(){
		if( !showAppointmentModal.modalOpen ){
			setShowAppointmentModal({modalOpen: true});
		}
	}
  function handleApptKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
			setShowAppointmentModal({modalOpen: true});
		}
  }


  const [showSmileAssessmentModal, setShowSmileAssessmentModal ] = useSmileAssessmentState(); 
  
  function showSmileAssessment(){
		if( !showSmileAssessmentModal.smileModalOpen ){
			setShowSmileAssessmentModal({smileModalOpen: true, iframeSrcToken: dentist.ACFDentist.dentistInfo.right.smileAssessment.smilematePracticeToken});
		}
	}


	const locations = dentistLocations.filter((location, index) => {

		if( location.ACFLocation && location.ACFLocation && location.ACFLocation.locationInfo.left.dentists ){
			const dentistIDs = location.ACFLocation.locationInfo.left.dentists.map(ortho =>
				`${ortho.id}`,
			);

			return dentistIDs && dentistIDs.includes(dentistId);
		} else {
			return null;
		}
	});

	return (
		<Layout>
			<Seo post={dentist} />  
      <div className={styleSheet.pagewrap}>
        <div className={styleSheet.top}>
          <div className={styleSheet.left}>
            <div className={styleSheet.lefttop}>
              { mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
              <div className={styleSheet.mobilebreadcrumbs}>{ teamPage && teamPage.nodes && teamPage.nodes.length > 0 && <Link className={styleSheet.loclink} to={teamPage.nodes[0].link}>Our Team</Link>} <span className={styleSheet.locationname} dangerouslySetInnerHTML={{__html: title}}></span></div>
              <div className={styleSheet.dentistinfo}>
                <h1 className={styleSheet.title} dangerouslySetInnerHTML={{__html: title}}></h1>
                { locations && locations.map( (location, index) => {
                  return <h2 className={styleSheet.locationheading}><Link key={location.id} className={styleSheet.locationlink} to={location.link} dangerouslySetInnerHTML={{__html: location.title}} /></h2>
                })}
              </div>
              <div className={styleSheet.dentistcontainer}>
                { headshot && <FluidImage image={headshot} passedClass={styleSheet.image} loading={"eager"} /> }
              </div>
            </div>
            <div className={styleSheet.leftbottom}></div>
          </div>
          <div className={styleSheet.right}>
            { introBGTablet && <FluidImage image={ introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
            {  introBGDesktop && <FluidImage image={ introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
            {  introBGXL && <FluidImage image={ introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
            <div className={styleSheet.desktopbreadcrumbs}>{ teamPage && teamPage.nodes && teamPage.nodes.length > 0 && <Link className={styleSheet.loclink} to={teamPage.nodes[0].link}>Our Team</Link>} <span className={styleSheet.locationname} dangerouslySetInnerHTML={{__html: title}}></span></div>
            { introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
            { introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
            { !makeAnAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>Book Your First Visit <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></button> }
            { makeAnAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{makeAnAppointmentLinkText} <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></button> }

          </div>
        </div>
        <div className={styleSheet.bottom}>
          <div className={styleSheet.left}>
            <div className={styleSheet.specialties}>
              <span className={styleSheet.heading}>{ specialitiesHeading ? specialitiesHeading : "Specialities" }</span>
              <ul className={styleSheet.list}>
                { specialities && specialities.map((service, index) => {
                  return (
                    <li key={`lstsrvc-'${service.id}`} className={styleSheet.listitem}>
                      <Link to={service.link} className={styleSheet.link}>
                        <FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
                        <span className={styleSheet.title} dangerouslySetInnerHTML={{__html: service.title }}></span>
                      </Link>	
                    </li>
                  )
                })}
              </ul>
            </div>
            { insurances && <div className={styleSheet.insurance}>
              <span className={styleSheet.heading}>{ insurancesHeading ? insurancesHeading : "We accept the following insurance" }</span>
              <div className={styleSheet.insurancebottom}>
                { insurances.map( (insurance, index) => {
                  const image = insurance.ACFInsurance.logo;
                  return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
                })}
              </div>
            </div>}
          </div>
          <div className={styleSheet.right}>
            {smileAssessment && smileAssessment.showSmileAssessment && (
              <section className={styleSheet.smileassessmentsection}>
                <div className={styleSheet.smileassessmentsectionbg} />
                  <div className={styleSheet.left}>
                    <div>
                      {smileAssessment.left.blurb && <h2 className={styleSheet.heading}>{smileAssessment.left.headline}</h2>}
                      {smileAssessment.left.blurb && <div dangerouslySetInnerHTML={{ __html: smileAssessment.left.blurb}} />}
                      {smileAssessment.left.blurb && <button className={styleSheet.button} onClick={showSmileAssessment}>{smileAssessment.left.buttonText}</button>}
                    </div>
                  </div>
                  <div className={styleSheet.right}>
                    { smileAssessment.right.image && <FluidImage image={smileAssessment.right.image} passedClass={styleSheet.sectionimage} />  }
                  </div>
              </section>
            )}
            <div className={styleSheet.dentistcontent}>
              <div className={styleSheet.contentleft}>
                <span className={styleSheet.gettoknow}>{ contentHeading ? contentHeading : `Get to know ${title}.` }</span>
                { content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
              </div>
            </div>
            { displayCallout && (
              <div className={styleSheet.callout}>
                <div className={styleSheet.calloutleft}>
                  { calloutHeading && <span className={styleSheet.heading} dangerouslySetInnerHTML={{__html: calloutHeading}}></span> }
                  { calloutBlurb && <p dangerouslySetInnerHTML={{__html: calloutBlurb}}></p> }
                </div>
                { calloutLink && <WPLink passedClass={styleSheet.link} link={calloutLink} /> }
              </div>
            )}
            {insurances && <div className={styleSheet.insurance}>
              <span className={styleSheet.heading}>We accept the following insurance.</span>
              <div className={styleSheet.insurancebottom}>
                { insurances.map( (insurance, index) => {
                  const image = insurance.ACFInsurance.logo;
                  return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
                })}
              </div>
            </div>}
          </div>
        </div>
      </div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  dentist: wpOrthodontist(id: {eq: $id}) {
    id
    title
		ACFDentist {
			headshot {
				localFile {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
			introBGTablet: headshot {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 560, layout: CONSTRAINED)
					}
				}
			}
			introBGDesktop: headshot {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 840, layout: CONSTRAINED)
					}
				}
			}
			introBGXL: headshot {
				localFile {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    ACFDentist {
			headshot {
				localFile {
        	childImageSharp {
          	gatsbyImageData(layout: FULL_WIDTH)
        	}
				}
      }
      introBGTablet: headshot {
				localFile {
        	childImageSharp {
          	gatsbyImageData(width: 560, layout: CONSTRAINED)
        	}
				}
      }
      introBGDesktop: headshot {
				localFile {
        	childImageSharp {
          	gatsbyImageData(width: 840, layout: CONSTRAINED)
        	}
				}
      }
      introBGXL: headshot {
				localFile {
        	childImageSharp {
          	gatsbyImageData(layout: FULL_WIDTH)
        	}
				}
      }
      dentistInfo {
        left {
          specialitiesHeading
          specialities {
            ... on WpService {
              id
              title
              link
            }
          }
          insurancesHeading
          insurances {
            ... on WpInsurance {
              id
              ACFInsurance {
                logo {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                    extension
                  }
                }
              }
            }
          }
        }
        right {
          introContent {
            blurb
            heading
            makeAnAppointmentLinkText
          }
          smileAssessment {
            showSmileAssessment
            smilematePracticeToken
            left {
              headline
              blurb
              buttonText
            }
            right {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  publicURL
                  extension
                }
              }
            }
          }
          contentHeading
          dentistContent
          callout {
            calloutBlurb
            calloutHeading
            calloutLink {
              target
              title
              url
            }
            displayCallout
          }
        }
      }
    }
  }
  locations: allWpLocation {
    nodes {
      title
      id
      link
      ACFLocation {
        locationInfo {
          left {
            dentists {
              ... on WpOrthodontist {
                id
              }
            }
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          mobileNavigationLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  teamPage: allWpPage(filter: {template: {templateName: {eq: "Team Page"}}}) {
    nodes {
      link
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
}
`;

export default DentistTemplate;
